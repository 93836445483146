import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "./assets/css/varibles.css";
import "./assets/css/font.css";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI, VueAwesomeSwiper);

Vue.config.productionTip = false;

var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?2846b12707342e7b27e75760d08ddaf5";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

new Vue({
  router,
  store,

  render: (h) => h(App),
}).$mount("#app");
